<template>
  <AbstractFreightCostInput
    :fixedShareCroppingValue="fixedShareCroppingValue"
    :isActualFreightCostTn="singleActivitySlice.isActualFreightCostTn"
    :variableShareCroppingValue="variableShareCroppingValue"
    :assignedHectares="singleActivitySlice.assignedHectares"
    :activitySliceId="singleActivitySlice.activitySliceId"
    :dynamicYield="singleActivitySlice.summary.dynamicYield"
    :freightCostTn="singleActivitySlice.freightCostTn"
    :isDraft="false"
    @saveClick="saveClick"
  />
</template>

<script>
import AbstractFreightCostInput from "../Abstract/AbstractFreightCostInput.vue";
import { mapGetters } from "vuex";
import { putRequest } from "../../managrx/serverCall";
export default {
  name: "freightCostInput",
  components: {
    AbstractFreightCostInput,
  },
  methods: {
    async saveClick(payload) {
      let response = await putRequest(
        `/activity_slice/${this.singleActivitySlice.id}`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("putResponseSuccess", {});
      }
      this.editMode = false;
    },
  },
  computed: {
    ...mapGetters(["singleActivitySlice"]),
    fixedShareCroppingValue() {
      return this.singleActivitySlice?.fixedSharecroppingPercent
        ? (1 / 10) *
            this.singleActivitySlice?.assignedHectares *
            this.singleActivitySlice?.summary?.dynamicYield *
            this.singleActivitySlice?.fixedSharecroppingPercent
        : 0;
    },
    variableShareCroppingValue() {
      return this.singleActivitySlice?.summary?.variableSharecroppingCosts
        ? this.singleActivitySlice?.summary?.variableSharecroppingCosts /
            this.singleActivitySlice?.summary?.forwardPrice
        : 0;
    },
  },
};
</script>
